.hero {
  display: flex;
  justify-content: space-between;
}
.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.hero-left {
  padding: 1.5rem 2rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hero-right {
  flex: 1;
  flex-direction: column;
  position: relative;
  background-color: var(--orange);
}

.hero-tag_line {
  margin-top: 4rem;
  color: white;
  background-color: #363d42;
  border-radius: 4rem;
  text-transform: uppercase;
  width: fit-content;
  padding: 20px 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.hero-tag_line-span {
  z-index: 2;
}

.hero-tag_line-div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.hero-heading {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}

.hero-sub_heading {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 1px;
  width: 70%;
  text-transform: none;
  line-height: 150%;
}

.hero-figures {
  display: flex;
  gap: 2rem;
}
.hero-figures > div {
  display: flex;
  flex-direction: column;
}
.hero-figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.hero-figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}
.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.hero-buttons > :nth-child(1) {
  background-color: var(--orange);
  color: white;
}
.hero-buttons > :nth-child(2) {
  background-color: transparent;
  border: 2px solid var(--orange);
  color: white;
}

.hero-right > .btn {
  color: black;
  position: absolute;
  right: 3rem;
  top: 2rem;
}

.fast-recovery {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  top: 7rem;
  right: 4rem;
}

.fast-recovery img {
  width: 2rem;
}

.fast-recovery > :nth-child(3) {
  color: var(--gray);
}
.fast-recovery > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}
.hero-img {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}
.hero-img-back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}
.bars {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  right: 28rem;
  top: 32rem;
}
.bars img {
  width: 3rem;
}

.bars > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bars > div > :nth-child(1),
.bars > div > :nth-child(3) {
  color: var(--gray);
}

.bars > div > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur {
    width: 14rem;
  }
  .hero-tag_line {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-buttons {
    justify-content: center;
  }
  .hero-sub_heading {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }

  .hero-heading {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }

  .figures > div > span:nth-of-type(2) {
    font-size: small;
  }
  .hero-right {
    position: relative;
    background: none;
  }
  .fast-recovery {
    left: 1rem;
    top: 2rem;
  }
  .bars {
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .bars > img {
    width: 2rem;
  }
  .bars > div > :nth-child(2) {
    color: white;
    font-size: 1rem;
  }
  .hero-img {
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
  }
  .hero-img-back {
    width: 15rem;
    left: 2rem;
    top: 0rem;
  }
}
