.Reasons {
  display: flex;
  padding: 0 2rem;
  gap: 2rem;
}
.Reasons-left {
  flex: 1;
}
.Reasons-right {
  flex: 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.Reasons-right > span {
  font-weight: bold;
  color: var(--orange);
}

.Reasons-right > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.Reasons-details-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Reasons-details-right > div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}
.Reasons-details-right > div > img {
  width: 2rem;
  height: 2rem;
}
@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
  }
  .Reasons-left img {
    width: 80vw;
  }
}
